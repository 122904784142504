<script>
  import axios from 'axios';

  // Function to dynamically set the Authorization header
  const setAuthorizationHeader = () => {
    const bearerToken = localStorage.getItem('bearer_token');
    if (bearerToken) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + bearerToken;
    } else {
      delete axios.defaults.headers.common['Authorization']; // Remove header if no token
    }
  };

  export default {
    methods: {
        
        getCurrentOrganization(organization_slug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getLiveEvent(organization_id, event_id) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_id}/live/${event_id}/player-data`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getFeatured(organization_slug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/featured`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getRecent(organization_slug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/recent`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getLiveSchedule (organization_slug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/live-schedule`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getVODCategories (organization_slug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand-categories`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getCategories (organization_slug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(res => {
                        console.log(res.data.data);
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getSliderLists (organization_slug, path_slug, data) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand/${path_slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params:data
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getLatestVideo(organization_slug){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/media/latest/player-data`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });

        },
        firstLoad(organization_slug ,path_slug){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand`, {
                    method: 'GET',
                    params:path_slug,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err);
                    })
            });

        },
        loadMore(organization_slug ,subcategories_slug ,path_slug){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand/${subcategories_slug}`, {
                    method: 'GET',
                    params:path_slug,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
            
        },
        getMedia(organization_search, media_search){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_search}/media/${media_search}/player-data`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });

        },
        getSubcategory(organization_slug, subcategories,path_slug){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand/${subcategories}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getSubcategories(organization_slug, subcategories,path_slug){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${organization_slug}/on-demand/${subcategories}/${path_slug}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        searchMedia(value, page,id){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/media/search?type=&term=${value}&sort%5Bcolumn%5D=name&sort%5Border%5D=asc&page=${page}&organization_id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        adImpression(id){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/advertisement/${id}/impressions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        adClickImpression(id){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/advertisement/${id}/clickthroughs`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        sendConnectMessage(slug,data){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${slug}/connections`, {
                    method: 'POST',
                    data:data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getInitMediaBySeries(slug,path_slug,data){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${slug}/on-demand${path_slug}`, {
                    method: 'GET',
                    params:data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        getMediaBySeries(slug,path_slug,data){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/organizations/${slug}/on-demand/${path_slug}`, {
                    method: 'GET',
                    params:data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        
    
        //CHAT CALLS:
        fetchAllMessages(eventId){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/events/${eventId}/chat/history`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        joinChatFetch(eventId,data){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/events/${eventId}/chat/connect`, {
                    method: 'POST',
                    data:data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },
        chatMessageFetch(eventId,data){
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/events/${eventId}/chat/messages`, {
                    method: 'POST',
                    data:data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },

        getOrganizationPlaylist(organizationSlug) {
            setAuthorizationHeader();
            return new Promise((resolve, reject) => {
                axios(`${import.meta.env.VITE_BASE_URL}/v1/schedule/${organizationSlug}/playlist`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => {
                        resolve(res.data.data);
                    })
                    .catch(err => {
                        reject(err.response.data);
                    })
            });
        },

    }
}
</script>
